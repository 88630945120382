html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  color: #4444;
  background-color: #161618;
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #ffd700
}